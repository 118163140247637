<template>
<v-container style="padding-top: 40px;">
    <v-dialog v-model="popup_features_dialog" max-width="290">
        <v-card>
            <div v-if="this.user_type=='user_patient'">
                <v-card-title class="text-h5">
                    ฟังก์ชั่นของผู้ป่วย/ผู้ดูแล
                </v-card-title>
                <v-card-text>
                    - ดูปฏิทินนัดพบหมอ<br>
                    - นัดหมอ<br>
                    - ดูข้อมูลของผู้ป่วย<br>
                    - Brain Library (ห้องสมุดสมอง)
                </v-card-text>
            </div>
            <div v-if="user_type=='user_provider'">
                <v-card-title class="text-h5">
                    โรงพยาบาล/คลินิก
                </v-card-title>
                <v-card-text>
                    - ดูสรุปข้อมูลผู้ป่วยทั้งหมด<br>
                    - ดูข้อมูลของผู้ป่วย<br>
                    - ทำแบบบันทึกประวัติสุขภาพให้ผู้ป่วย<br>
                    - ดูปฏิทินรายการนัดหมายจากผู้ป่วย<br>
                    - Brain Library (ห้องสมุดสมอง)<br>
                    - VDO สอนการตรวจประเมิน
                </v-card-text>
            </div>

            <div v-if="user_type=='user_osm'">
                <v-card-title class="text-h5">
                    อสม.
                </v-card-title>
                <v-card-text>
                    - ดูสรุปข้อมูลผู้ป่วยทั้งหมด<br>
                    - ดูข้อมูลของผู้ป่วย<br>
                    - ทำแบบบันทึกประวัติสุขภาพให้ผู้ป่วย<br>
                    - Brain Library (ห้องสมุดสมอง)<br>
                    - VDO สอนการตรวจประเมิน
                </v-card-text>
            </div>


            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#A84192" outlined @click="goRegister(user_type)">
                    ลงทะเบียน
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-row>
        <v-col class="font-weight-bolder text-primary" style="text-align: center" cols="12">
            ลงทะเบียนเข้าสู่ระบบ
        </v-col>
    </v-row>
    <v-row style="justify-content: center">
        <v-col xl="3" lg="4" sm="6" class="" cols="12">
            <v-card class="" style="border-radius: 8px; background: #fafbff">
                <v-card-text>
                    <v-container>
                        <v-row dense>
                            <!-- <v-container cols="12" class="select-type-card" @click="goRegister('user_patient')"> -->
                            <v-container cols="12" class="select-type-card" @click="popupFeatures('user_patient')">
                                <v-row align="center" style="margin-top: -8px">
                                    <v-col cols="4" style="text-align: -webkit-right">
                                        <v-img src="@/assets/img/checkmong/pt-icon.png" width="50" style="border-radius: 256px"></v-img>
                                    </v-col>
                                    <v-col class=" text-primary" cols="8" style="padding-left: 23px">
                                        ผู้ป่วย/ผู้ดูแล
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container cols="12" class="select-type-card" @click="popupFeatures('user_provider')">
                                <v-row align="center" style="margin-top: -8px">
                                    <v-col cols="4" style="text-align: -webkit-right">
                                        <v-img src="@/assets/img/checkmong/prv-icon.png" width="50" style="border-radius: 256px"></v-img>
                                    </v-col>
                                    <v-col class="text-primary" cols="8" style="padding-left: 23px">
                                        โรงพยาบาล/คลินิก
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container cols="12" class="select-type-card" @click="popupFeatures('user_osm')">
                                <v-row align="center" style="margin-top: -8px">
                                    <v-col cols="4" style="text-align: -webkit-right">
                                        <v-img src="@/assets/img/checkmong/osm-icon.png" width="50" style="border-radius: 256px"></v-img>
                                    </v-col>
                                    <v-col class=" text-primary" cols="8" style="padding-left: 23px">
                                        อสม.
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: "register-select-type",
    data() {
        return {
            popup_features_dialog: false,
            user_type: null,
        };
    },
    mounted() {
        //console.log(this.$store.state)

    },

    methods: {
        popupFeatures(value) {
            this.user_type = value
            this.popup_features_dialog = true
        },
        goRegister(user_type) {

            const lineid = this.$store.state.register_form.lineid
            this.$store.commit('setRegisterForm', {
                user_type: user_type,
                lineid: lineid
            });
            this.$router.push('/pdpa')
        }
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-main__wrap {
    /*align-self: center;*/

    padding: 14px 14px 14px 14px;
    background-color: white;
}

.select-type-card {
    width: 246px;
    height: 85px;
    box-shadow: 0px 1px 5px #f2f2f2;
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 10px;
    margin-top: 10px;
}

.select-type-card:hover {
    background: #b6b6b6;
}

.select-type-card:active {
    background: #b6b6b6;
}
</style>
